import React from 'react'
import Select from 'react-select'

const MultiPicker = ({ selected, options }) => {
  return (
    <>
      <Select
        isMulti
        name="user[industry_interest][]"
        options={options}
        defaultValue={selected}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="You may pick more than one"
      />
    </>
  )
}

export default MultiPicker
