import * as bootstrap from 'bootstrap'
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper'

function isTouchDevice() {
  return window.ontouchstart !== undefined
}

function toggleInput(id, activate) {
  if (activate) {
    $(id).show()
    $(`${id} select`).prop('disabled', false)
  } else {
    $(id).hide()
    $(`${id} select`).prop('disabled', true)
  }
}

function animateValue(id, start, end, duration) {
  var range = end - start
  var minTimer = 50
  var stepTime = Math.abs(Math.floor(duration / range))

  // Minimum timer value to avoid too frequent updates
  stepTime = Math.max(stepTime, minTimer)

  var currentValue = start
  var increment = range / (duration / stepTime)

  var obj = $(id)
  var timer = setInterval(function () {
    currentValue += increment
    $(obj).text(Math.round(currentValue))
    if (
      (start < end && currentValue >= end) ||
      (start > end && currentValue <= end)
    ) {
      $(obj).text(end)
      clearInterval(timer)
    }
  }, stepTime)
}

export const patchBootstrap = () => {
  const tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  )
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
  const popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  )
  popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })
}

export const initPricingModal = () => {
  const countrySelection = document.getElementById('countryModal')

  if (!countrySelection) return
  console.log('initPricingModal')

  if (countrySelection) {
    const pricingModal = new bootstrap.Modal(countryModal, {
      keyboard: false,
      backdrop: 'static',
    })

    pricingModal.show()
  }
}

export const initCountdown = () => {
  document.addEventListener('readystatechange', (e) => {
    if (!document.querySelector('.count-down')) return
    console.log('initCountdown')

    if ('complete' === e.target.readyState) {
      for (
        var n = document.getElementsByClassName('count-down'),
          t = new Array(),
          i = 0;
        i < n.length;
        i++
      )
        (t[i] = new Array()),
          (t[i].el = n[i]),
          (t[i].time = new Date(n[i].getAttribute('data-date')).getTime()),
          (t[i].days = 0),
          (t[i].hours = 0),
          (t[i].seconds = 0),
          (t[i].minutes = 0)
      setInterval(function () {
        for (var e = 0; e < t.length; e++) {
          var n = new Date().getTime(),
            n = t[e].time - n
          ;(t[e].days = Math.floor(n / 864e5)),
            (t[e].hours = Math.floor((n % 864e5) / 36e5)),
            (t[e].minutes = Math.floor((n % 36e5) / 6e4)),
            (t[e].seconds = Math.floor((n % 6e4) / 1e3)),
            n < 0
              ? ((t[e].el.querySelector('.days').innerHTML = 0),
                (t[e].el.querySelector('.hours').innerHTML = 0),
                (t[e].el.querySelector('.minutes').innerHTML = 0),
                (t[e].el.querySelector('.seconds').innerHTML = 0))
              : ((t[e].el.querySelector('.days').innerHTML = t[e].days),
                (t[e].el.querySelector('.hours').innerHTML = t[e].hours),
                (t[e].el.querySelector('.minutes').innerHTML = t[e].minutes),
                (t[e].el.querySelector('.seconds').innerHTML = t[e].seconds))
        }
      }, 1e3)
    }
  })
}

export const initHamburgerMenu = () => {
  const hamburger = $('#menuToggle')

  if (!hamburger.length) return
  console.log('initHamburgerMenu')

  $(window).on('scroll', function () {
    const scrollPos = $(window).scrollTop()
    const windowHeight = $(window).height()
    if (scrollPos > windowHeight) {
      // $('.exhibitCta').addClass('hide')
    } else {
      // $('.exhibitCta').removeClass('hide')
    }
  })

  hamburger.on('click', function (e) {
    e.preventDefault()
    if ($(this).hasClass('active-menu')) {
      // when menu is closed
      $('#navOverlay')
        .stop()
        .animate({ top: '-100%' }, 500, 'easeInCubic', function () {
          $(this).hide()
          $('body').removeClass('overflow-hidden')
          $('#navOverlay').removeClass('show')
          $('.navCta').removeClass('d-none')
        })
      $(this).removeClass('active-menu')
    } else {
      // when menu is open
      $('.navCta').addClass('d-none')

      $('#navOverlay')
        .stop()
        .css({ top: '-100%' })
        .show()
        .animate({ top: '0%' }, 500, 'easeInCubic', function () {
          $('body').addClass('overflow-hidden')
          $('#navOverlay').addClass('show')
        })
      $(this).addClass('active-menu')
    }
  })
}

export const initHomeContactForm = () => {
  const n = $('#contact-form')
  const t = $('.form-message')

  if (!n.length || !t.length) return
  console.log('initHomeContactForm')

  $(n).on('submit', function (e) {
    e.preventDefault()
    e = $(n).serialize()
    $.ajax({ type: 'POST', url: $(n).attr('action'), data: e })
      .done(function (e) {
        $(t).removeClass('error'),
          $(t).addClass('success'),
          $(t).text(e),
          $(
            '#contact-form input:not(:hidden):not(.radio_buttons), #contact-form textarea'
          ).val(''),
          $('input:radio').prop('checked', false),
          toggleInput('#sponsorship_package', false),
          toggleInput('#exhibitor_package', false)
      })
      .fail(function (e) {
        $(t).removeClass('success'),
          $(t).addClass('error'),
          '' !== e.responseText
            ? $(t).text(e.responseText)
            : $(t).text(
                'Oops! An error occured and your message could not be sent.'
              )
      })
  })
}

export const initSponsorTierSwiper = () => {
  if (!$('#sponsorshipTiersSwiper').length) return

  console.log('initSponsorTierSwiper')
  const swiper = new Swiper('#sponsorshipTiersSwiper', {
    modules: [Autoplay, Navigation, Pagination],
    pagination: {
      el: '.swiper-pagination',
    },
    slidesPerView: 1,
    spaceBetween: 10,
    // breakpoints: {
    //   400: {
    //     slidesPerView: 2,
    //   },
    //   600: {
    //     slidesPerView: 3,
    //   },
    //   724: {
    //     slidesPerView: 4,
    //   },
    //   900: {
    //     slidesPerView: 5,
    //   },
    // },
  })
}

export const initMobilePricingSwiper = () => {
  if (!$('#mobilePricingSwiper').length) return

  console.log('initMobilePricingSwiper')
  const swiper = new Swiper('#mobilePricingSwiper', {
    modules: [Autoplay, Navigation, Pagination],
    pagination: {
      el: '.swiper-pagination',
    },
    slidesPerView: 1,
  })
}

export const initExhibitContactForm = () => {
  const n = $('#exhibit-contact')
  if (!n.length) return
  console.log('initExhibitContactForm')

  toggleInput('#sponsorship_package', false)
  toggleInput('#exhibitor_package', false)

  $('.radio_buttons').on('change', function (e) {
    if (e.target.value == 'Sponsor') {
      toggleInput('#sponsorship_package', true)
      toggleInput('#exhibitor_package', false)
    } else if (e.target.value == 'Exhibitor') {
      toggleInput('#sponsorship_package', false)
      toggleInput('#exhibitor_package', true)
    }
  })
}

export const initSponsorStats = () => {
  if (!$('.statNum').length) return
  console.log('initSponsorStats')

  // animateValue('.statNum:eq(0)', 0, 3, 2000) //Days
  animateValue('.statNum:eq(0)', 0, 10000, 2000) //Delegates
  animateValue('.statNum:eq(1)', 0, 80, 2000) //Countries
  animateValue('.statNum:eq(2)', 0, 100, 2000) //Speakers
  animateValue('.statNum:eq(3)', 0, 100, 2000) //Exhibitors
}

const getTimeRemaining = (endtime) => {
  var t = Date.parse(endtime) - Date.parse(new Date());
  return {
    'Total': t,
    'Days': Math.floor(t / (1000 * 60 * 60 * 24)),
    'Hours': Math.floor((t / (1000 * 60 * 60)) % 24),
    'Minutes': Math.floor((t / 1000 / 60) % 60),
    'Seconds': Math.floor((t / 1000) % 60)
  };
}

function CountdownTracker(label, value){
  var el = document.createElement('span');

  el.className = 'flip-clock__piece';
  el.innerHTML = '<span class="flip-clock__slot">&nbsp;</span><span class="flip-clock__card time-card"><span class="card__top"></span><span class="card__bottom"></span><span class="card__back"><span class="card__bottom"></span></span></span>' + 
    '<span class="flip-clock__slot">' + label + '</span>';

  this.el = el;

  var top = el.querySelector('.card__top'),
      bottom = el.querySelector('.card__bottom'),
      back = el.querySelector('.card__back'),
      backBottom = el.querySelector('.card__back .card__bottom');

  this.update = function(val){
    val = ( '0' + val ).slice(-2);
    if ( val !== this.currentValue ) {
      
      if ( this.currentValue >= 0 ) {
        back.setAttribute('data-value', this.currentValue);
        bottom.setAttribute('data-value', this.currentValue);
      }
      this.currentValue = val;
      top.innerText = this.currentValue;
      backBottom.setAttribute('data-value', this.currentValue);

      this.el.classList.remove('flip');
      void this.el.offsetWidth;
      this.el.classList.add('flip');
    }
  }
  this.update(value);
}

function Clock() {
  let countdown = new Date(Date.parse(new Date(Date.parse(new Date(2023, 9, 4, 9, 0)) + 0 * 24 * 60 * 60 * 1000)));
  this.el = document.createElement('div');
  this.el.className = 'flip-clock';

  var trackers = {},
      t = getTimeRemaining(countdown),
      key, timeinterval;
  for ( key in t ){
    if ( key === 'Total' ) { continue; }

    // countdown is negative => show all 0
    if (t.Total < 0) {
      trackers[key] = new CountdownTracker(key, 0);
      this.el.appendChild(trackers[key].el);
    } else {
      trackers[key] = new CountdownTracker(key, t[key]);
      this.el.appendChild(trackers[key].el);
    }
  }

  let interval;

  interval = setInterval(function () {
    var t = getTimeRemaining(countdown)

    if ( t.Total < 0 ) {
      clearInterval(interval)
      for ( key in trackers ){
        trackers[key].update( 0 )
      }
      return
    }
    for ( key in trackers ){
      trackers[key].update( t[key] )
    }
  }, 1e3)
}
export const initCountdownTimer = () => {
  document.addEventListener('readystatechange', (e) => {
    if (!document.querySelector('#countdown-timer')) return
    
    if ('complete' === e.target.readyState) {
      console.log('initCountdownTimer')
      var c = new Clock();
      document.querySelector("#countdown-timer").appendChild(c.el);
    }
  })
}

export const initAttractionsSwiper = () => {
  if (!$('#exploreSwiper').length) return

  console.log('initAttractionsSwiper')
  const swiper = new Swiper('#exploreSwiper', {
    modules: [Navigation],
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 10,
    centeredSlides: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      400: {
        slidesPerView: 2,
        slidesPerGroup: 5,
        spaceBetween: 15,
      },
      600: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 20,
      },
      724: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 25,
      },
      900: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 30,
      },
    },
  })
}
export const initExplorePageFadeUp = () => {
  if (!$('.attraction-item').length) return
  
  const elements = document.querySelectorAll('.attraction-item')
  
  console.log('initExplorePageFadeUp')
  const callback = (items) => {
    items.forEach(item => {
      if (item.isIntersecting) {
        item.target.classList.add('fadeUp')
      }
    })
  }
  const observer = new IntersectionObserver(callback, {})

  elements.forEach(element => {
    observer.observe(element)
  })
}

export const initMediaSwiper = () => {
  if (!$('#mediaSwiper').length) return

  console.log('initMediaSwiper')
  const swiper = new Swiper('#mediaSwiper', {
    modules: [Autoplay, Navigation, Pagination],
    // pagination: {
    //   el: '.swiper-pagination',
    // },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    slidesPerView: 1,
  })
}

export const initExhibitionSwiper = () => {
  if (!$('#exhibitionSwiper').length) return

  console.log('initExhibitionSwiper')
  const swiper = new Swiper('#exhibitionSwiper', {
    modules: [Autoplay, Pagination],
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    breakpoints: {
      475: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 10,
      },
      900: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 10,
      },
    },
  })
}
export const initPreEventsSwiper = () => {
  if (!$('#preeventsHeroSwiper').length) return

  console.log('initPreEventsSwiper')
  const swiper = new Swiper('#preeventsHeroSwiper', {
    modules: [Autoplay, Pagination],
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    slidesPerView: 1,
  })
}