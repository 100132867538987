require('@rails/ujs').start()
require('turbolinks').start()
require('jquery') // for cocoon
require('@nathanvda/cocoon') // for nested forms
import '@popperjs/core'
import 'jquery.easing'
import '../stylesheets/application'

import 'swiper/swiper-bundle.css'

import axios from 'axios'
window.axios = axios

var componentRequireContext = require.context('components', true)
var ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)

import '@popperjs/core'
import {
  initCountdownTimer,
  initHamburgerMenu,
  initMobilePricingSwiper,
  initPricingModal,
  patchBootstrap,
  initAttractionsSwiper,
  initExplorePageFadeUp,
  initSponsorTierSwiper,
  initSponsorStats,
  initExhibitContactForm,
  initMediaSwiper,
  initExhibitionSwiper,
  initPreEventsSwiper
} from './shared_helpers'

import '../stylesheets/application'

initCountdownTimer()

document.addEventListener('DOMContentLoaded', function (event) {
  patchBootstrap()
  initHamburgerMenu()

  initPricingModal()
  initMobilePricingSwiper()

  initSponsorTierSwiper()
  initAttractionsSwiper()
  initExplorePageFadeUp()
  initExhibitContactForm()
  initSponsorStats()
  initMediaSwiper()
  initExhibitionSwiper()
  initPreEventsSwiper()
})
