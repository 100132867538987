import axios from 'axios'
import React, { useEffect, useState } from 'react'

const OrderSummary = ({
  skipPromo = false,
  appliedCode = '',
  passType,
  passName,
  currency,
  priceInCents,
  displayPrice,
}) => {
  const [promo, setPromo] = useState(appliedCode)
  const [status, setStatus] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (promo) {
      setLoading(true)
      axios({
        method: 'post',
        url: '/users/validate_coupon',
        data: {
          promo_code: promo,
          pass_type: passType,
        },
      })
        .then((response) => {
          setStatus(response.data)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [])

  const handleInput = (e) => {
    setPromo(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    axios({
      method: 'post',
      url: '/users/validate_coupon',
      data: {
        promo_code: promo,
        pass_type: passType,
      },
    })
      .then((response) => {
        const couponInput = document.getElementById('couponInput')
        if (couponInput) {
          couponInput.value = response?.data?.valid ? promo : ''
        }
        setStatus(response.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const finalPrice = () => {
    if (status?.price == null) {
      return (
        <div>
          {currency} {priceInCents / 100}
        </div>
      )
    } else {
      return (
        <div>
          <span>{currency}</span>&nbsp;
          <s className="text-muted">{priceInCents / 100}</s>&nbsp;
          <span className="text-success">{status.price / 100}</span>
        </div>
      )
    }
  }

  const passTier = passType.split('_')[0]

  const displayPassFeatures = () => {
    if (passTier === 'virtual') {
      return (
        <>
          <li>Mobile App Messaging Function</li>
          <li>Meeting Scheduler Function</li>
          <li>Searchable Attendee & Exhibitor List</li>
          <li>Downloadable Contacts</li>
        </>
      )
    } else if (passTier === 'exhibition') {
      return (
        <>
          <li>Exhibition Floor Access</li>
          <li>Mobile App Messaging Function</li>
          <li>Meeting Scheduler Function</li>
          <li>Scanning & Networking Tool</li>
          <li>Searchable Attendee & Exhibitor List</li>
        </>
      )
    } else {
      return (
        <>
          <li>Exhibition Floor Access</li>
          <li>
            Curated WCIT | IDECS 2023 Content Across All Tracks (4-6 October
            2023)
          </li>
          {passTier != 'regular' && (
            <li>Masterclass Access (subject to room capacity)</li>
          )}
          <li>Mobile App Messaging Function</li>
          <li>Meeting Scheduler Function</li>
          <li>Scanning & Networking Tool</li>
          <li>Searchable Attendee & Exhibitor List</li>
          <li>Downloadable Contacts</li>
          <li>Exclusive Perks from our Partners</li>
          {passTier != 'regular' && (
            <>
              {passTier != 'all_access' && (
                <>
                  <li>Leaders Lounge Access</li>
                  <li>Leaders Networking Reception</li>
                </>
              )}
              <li>Welcome Reception</li>
              <li>Gala & Awards Dinner</li>
            </>
          )}
          {/* <li>Farewell Party</li> */}
        </>
      )
    }
  }

  return (
    <>
      <div className="upper">
        <div className="ticketWrapper">
          <div className="ticketName">{passName}</div>
          <div className="ticketPrice">{displayPrice}</div>
        </div>
        <ul className="mt-3 featureList">{displayPassFeatures()}</ul>
        {!skipPromo && (
          <>
            <hr />
            <div className="promoCodeWrapper">
              <label>Have promo code?</label>

              <div className="input-group mb-1">
                <input
                  type="text"
                  className={`form-control ${
                    status == 'success' && 'is-valid'
                  }`}
                  placeholder="Promo Code"
                  value={promo}
                  onChange={handleInput}
                  disabled={loading}
                />
                <div className="input-group-append">
                  <button
                    id="promoSubmitBtn"
                    type="button"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Apply
                  </button>
                </div>
              </div>
              <small
                className={`d-block text-${
                  status?.valid ? 'success' : 'danger'
                } text-center`}
              >
                {status?.message && status.message}
              </small>
            </div>
          </>
        )}
      </div>
      <div className="lower py-3">
        <div>Total</div>
        {finalPrice()}
      </div>
    </>
  )
}

export default OrderSummary
