import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const PhoneField = ({ phone }) => {
  return (
    <>
      <PhoneInput
        value={phone}
        country="my"
        inputProps={{
          name: 'user[phone]',
          required: true,
        }}
      />
    </>
  )
}

export default PhoneField
